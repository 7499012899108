





















































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: '이용약관'
      }
    }
  }
})
export default class Terms extends Vue {
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
}
